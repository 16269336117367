const rfiFormWrappers = document.querySelectorAll('.rfi-form_wrapper');

rfiFormWrappers.forEach( (form)=>{
  form.addEventListener('submit',()=>{
    const formNumber = form.id.substr(14)
    if( formNumber > 0 ){
      const submitButton = document.getElementById('gform_submit_button_' + formNumber );
      submitButton.value = 'Processing, please wait...';
    }       
  });
});

//Blog in Post RFI
const uwexPGWidget = document.getElementById('uwex-bd-program-guide-widget');
const formFront = document.querySelector('.form-front-section');

if( uwexPGWidget ){
  uwexPGWidget.addEventListener('click',( e )=>{
    let target = e.target;
    if(target.id === 'slide'){
      e.preventDefault();
      uwexPGWidget.classList.add('open');
      if(formFront){
        formFront.classList.add('slide-over');
      }
    }
  });
}