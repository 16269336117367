/*!
  * Bootstrap v4.6.0 (https://getbootstrap.com/)
  * Copyright 2011-2021 The Bootstrap Authors (https://github.com/twbs/bootstrap/graphs/contributors)
  * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
  * 
  * bootstrap/ is Bootstrap library files stored in node_modules
  * vendor/ is used to extend 3rd party functionality (Gravity Forms, Yoast, etc)
  * custom/ is functionality used for site specific tasks (Slide-in-out forms, etc)
*/

import Alert from "bootstrap/js/dist/alert";
import Button from "bootstrap/js/dist/button";
import Carousel from "bootstrap/js/dist/carousel";
import Collapse from "bootstrap/js/dist/collapse";
import Dropdown from "bootstrap/js/dist/dropdown";
import Modal from "bootstrap/js/dist/modal";
//import Popover from "bootstrap/js/dist/popover";
//import ScrollSpy from "bootstrap/js/dist/scrollspy";
import Tab from "bootstrap/js/dist/tab";
//import Toast from "bootstrap/js/dist/toast";
import Tooltip from "bootstrap/js/dist/tooltip";
import LinkSkip from "./custom/skip-link-focus-fix";
import Navigation from "./custom/nav";
import playStop from "./custom/stopVideoPlay";
import * as rfiForms from "./custom/rfiForms";
import search from "./custom/search";
import defer from "./custom/deferFrames";
import footer from "./custom/footer";
import tagManager from "./vendor/googletagmanager";
import customAccordion from "./custom/accordion";
import capstone from "./custom/capstone";

export default {
  Alert,
  Button,
  Carousel,
  Collapse,
  Dropdown,
  Modal,
  Tab,
  LinkSkip,
  Navigation,
  Tooltip,
  footer,
  playStop,
  rfiForms,
  defer,
  search,
  tagManager,
  customAccordion,
  capstone
};
