//Progressive Enhancement of all search forms
const searchForm = document.querySelectorAll('.searchform');;

if(searchForm){
  searchForm.forEach(( sform )=>{
    let hiddenSite = sform.querySelector('.hidden-site');
    let searchInput = sform.querySelector('.search-input');
    if( hiddenSite ){
      sform.removeChild(hiddenSite);
    }
    if( searchInput ){
      searchInput.setAttribute('name','q')
    }
    // eslint-disable-next-line no-undef
    sform.setAttribute('action',location.protocol + '//'+ location.host + '/search');
  });
}
