import Collapse from "bootstrap/js/dist/collapse";

const html = document.getElementsByTagName('html')[0];
html.classList.remove('no-js');
html.classList.add('js');

document.addEventListener('DOMContentLoaded', function() {
  const navbar = document.querySelector('.navbar-collapse');
  if ( !navbar ) { return; }
  const navbarCollapse = Collapse.getOrCreateInstance(navbar, {'toggle':false} ); // false toggle prevents collapse from opening on page load
  document.addEventListener('click', function(e) {
    if (!e.target.matches('a')) {
      navbarCollapse.hide();
    }
  });
});

const dropdownMenus = document.querySelectorAll('.dropdown-menu');
dropdownMenus.forEach(function(menu) {
  const firstLi = menu.querySelector('li');
  const backButton = document.createElement('a');
  backButton.setAttribute('data-toggle', 'close');
  backButton.classList.add('back-button');
  backButton.textContent = 'Back';
  menu.insertBefore(backButton, firstLi);
});

const wrapper = document.querySelector('#navbarNavDropdown');

const dropdownToggles = document.querySelectorAll('a.dropdown-toggle');
dropdownToggles.forEach(function(toggle) {
  toggle.addEventListener('click', function() {
    wrapper.classList.toggle('darkBg');
  });
});

const heroInner = document.querySelector(".inner-hero");
const studentHero = document.querySelector(".super-heros");

if  ( heroInner && studentHero ) {
  let heroDimensions = studentHero.getBoundingClientRect();

  // Set inital position
  heroInner.style = `max-width: ${parseInt(heroDimensions.x)}px`;

  function reportWindowSize() {
    heroDimensions = studentHero.getBoundingClientRect();
    heroInner.style = `max-width: ${parseInt(heroDimensions.x)}px`;
  }
  //Watch for resizing
  window.onresize = reportWindowSize;
}
