import Collapse from "bootstrap/js/dist/collapse";

document.addEventListener('DOMContentLoaded', function() {

if( window.location.hash ){
  let btn_wrap = document.getElementById( window.location.hash.replace( '#','' ) );
  
  if( btn_wrap ){
    let btnCollapse = btn_wrap.querySelector( '[data-bs-toggle="collapse"]' );
    if( btnCollapse ){
      let collapseID = btnCollapse.getAttribute('data-bs-target');
      btn_wrap.scrollIntoView({behavior: "smooth"});

      const collapseElement = document.querySelector(collapseID);
      if (collapseElement) {
        Collapse.getOrCreateInstance(collapseElement).show();
      }
    }

  }

}
});