
  const listButton = document.getElementById('btn-list');
  const capFiltersForm = document.getElementById('capFiltersForm');
  const capFilters = document.querySelector('.cap-filters');
  const capCatWrap = document.getElementById('cap-cat-wrap');
  const searchInput = document.getElementById('searchInput');
  const searchClear = document.getElementById('search-clear');
  //Create the capstone URL
  const capName = '/capstone-projects/';
  const capIndex = window.location.href.indexOf(capName) + (capName.length-1);
  const capURL = window.location.href.substr( 0, capIndex );//get the capstone URL when the page loads.

  //Toggle Capstone Archive View
  const toggleView = e => {
    const catalog = document.querySelector("#catalog");

    if (e.srcElement.innerHTML === "Grid View") {
      e.srcElement.innerHTML = "List View";
    } else {
      e.srcElement.innerHTML = "Grid View";
    }

    catalog.classList.toggle("list-view");
  };

  //Get Checked Filters
  const getCheckedFilters = ()=>{
    let checkBoxes = document.querySelectorAll( '.form-check-input' );
    let checkedValues = [];

    checkBoxes.forEach( item=>{
      if( item.checked ){
        // let group = item.dataset.group;
        let group = item.getAttribute('data-query_param');
        if ( !checkedValues[group] ) {
          checkedValues[group] = [];
        }
        checkedValues[group].push( item.value )
      }
    });

    return checkedValues;
  }

  // Clear Checked Filters
  const clearCheckedFilters = ( grouping )=>{
    // let checkBoxes = document.querySelectorAll( '[data-group="' + grouping + '"]' );
    let checkBoxes = document.querySelectorAll( '[data-query_param="' + grouping + '"]' );
    checkBoxes.forEach( item=>{
      item.checked = false;
    });
  }

  //Build query string to send to Wordpress
  const buildFilterQuery = ()=>{
    let filters = getCheckedFilters();
    let queryString = '';
    if( searchInput.value !== '' ){
      queryString+='&searchInput=' + searchInput.value;
    }
    for (const [key, values] of Object.entries( filters ) ) {
      queryString += '&' + key + '=';
      values.forEach( item =>{
        queryString+=item + ',';
      });
      //chop last comma
      queryString = queryString.substring(0, queryString.length - 1);
    }
    return queryString;
  }
  // Submit Filter parameters to Wordpress
  const submitFilter = function( page = '', queryString ){
    // Set up our HTTP request
    // eslint-disable-next-line no-undef
    let xhr = new XMLHttpRequest();    
    let pagenum_url = '';
    
    if(page && page.length !== 0){
      pagenum_url = '&paged=' + page;
    }

    let queryURL = encodeURI( '?action=filter_capstone' + queryString + pagenum_url );
    xhr.onreadystatechange = function () {
      if( this.readyState < 4 ){
        capCatWrap.innerHTML = '<div class="col text-center p-2"><div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div></div>';
        //To Do: Set a timer here to stop the loading and show a failed message. - Yer
      }
      if ( this.readyState === 4  && this.status === 200 ) {
        capCatWrap.innerHTML = this.responseText;
      }
    };
    // eslint-disable-next-line no-undef
    xhr.open('GET', WPURLS.ajax_url + queryURL , true);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded;');
    xhr.send();
  };

  //Build friendly URL
  const buildHistoryURL = ( queryString )=>{
    queryString = queryString.replace(/&/g, '/');
    queryString = queryString.replace(/=/g, '/');
    return queryString;
  }

  //Update page URL
  const updateURL = ( queryString )=>{
    let query = buildHistoryURL( queryString );
    if( query !== '' ){
      // eslint-disable-next-line no-undef
      history.pushState(null, null, capURL + query)
    }
    else{//going back go recent with no filters selected
      // eslint-disable-next-line no-undef
      history.pushState(null, null, capURL);
    }
  };

  const getPageNumber = ( url ) =>{
    let pIndex = url.indexOf("/page/");//find the first occurence of 'page' in the url
    let page = 0;
    if( pIndex > 0 ){
      let numberURL = url.substr( pIndex + 6 ,11 );// 11 spaces for unlikely number
      page = numberURL.slice(0,-1)
    }
    return page;
  }

  //Update pagination URLs
  const updatePagiURL = ( queryString, page )=>{
    let query = buildHistoryURL( queryString );
    let page_query = '';
    if( page > 1 ){
      page_query = '/page/' + page;
    }
    if( query !== '' ){
      // eslint-disable-next-line no-undef
      history.pushState( null, null, capURL + query + page_query )
    }
    else{//going back go recent with no filters selected
      // eslint-disable-next-line no-undef
      history.pushState( null, null, capURL + page_query );
    }
  }

  if( listButton ){
    listButton.addEventListener("click", toggleView);
  }

  if( capFiltersForm ){
    //Submit Search Form
    capFilters.addEventListener('submit',e=>{
      e.preventDefault();
      let queryString = buildFilterQuery();
      //always set page to 0 when searching
      submitFilter( 0, queryString );
    });

    //Hide and Show cancel button on Search text
    capFilters.addEventListener('input',e=>{
      let target = e.target;
      if( target.id === 'searchInput' ){
        if( target.value.length ){
          searchClear.classList.remove('hidden');

        }
        else{
          searchClear.classList.add('hidden');
        }
      }
    });

    //Click Event listener
    capFilters.addEventListener('click', e=>{
      let target = e.target;
      //Clear search box
      if( target.id === 'search-clear' ){
        searchInput.value='';
        searchClear.classList.add('hidden');
        let queryString = buildFilterQuery();
        updateURL( queryString );
        submitFilter( 0, queryString );
        searchInput.focus();
      };
      //Checkbox check
      if( target.matches('.form-check-input') ){
        let queryString = buildFilterQuery();
        updateURL( queryString );
        //always set page to 0 when selecting new filter
        submitFilter( 0, queryString );
      };
      // Clear Filters
      if( target.matches('.clearFilters') ){
        e.preventDefault();
        // grouping = target.dataset.grouping;
        let grouping = target.getAttribute('data-query_param_reset');
        clearCheckedFilters( grouping );
        
        let queryString = buildFilterQuery();
        updateURL( queryString );
        //always set page to 0 when selecting new filter
        submitFilter( 0, queryString );
      }
      
    });
  }

  if( capCatWrap ){
    //Pagination listenter
    capCatWrap.addEventListener( 'click', e=>{
      let target = e.target;
      //pagination links are built with php in blog.php with the filter get_pagenum_link
      if( target.matches('.page-link') ){
        e.preventDefault();
        let queryString = buildFilterQuery();
        if( target.href ){//prevent doing anything when they click on the number one on the first page
          let page = getPageNumber( target.href );
          updatePagiURL( queryString, page );
          submitFilter( page, queryString );
        }
      }
    });
  }