const banner = document.querySelector("#child-banner");

if (banner !== null) {
  const bannerButton = document.querySelector("#child-banner img.close-bottom");
  const urlPath = window.location.pathname.replace(/^[\\/]+|[\\/]+$/g, "");

  let bannerStatus = window.localStorage.getItem("bannerStatus");
  if (bannerStatus === "undefined") {
    window.localStorage.clear();
  }

  let storage = JSON.parse(window.localStorage.getItem("bannerStatus"));

  const findOccurrences = (dataset, search) => {
    if (dataset === null) return;

    var num = dataset.reduce(function(n, val) {
      return n + (val.site === search);
    }, 0);

    return num;
  };

  const getBannerStatus = siteKey => {
    let response = "";
    if (storage !== null && storage.length > 0 && Array.isArray(storage)) {
      // eslint-disable-next-line array-callback-return
      response = storage.filter(state => {
        if (state.site === siteKey) {
          //match the site key
          return state.status;
        }
        if (setBannerStatus(siteKey, "open")) {
          return state.status; //only return when a new banner status is added
        }

      });
      return response[0].status;
    }
  };

  const setBannerStatus = (siteKey, desiredStatus) => {
    if (
      (desiredStatus === "open" && findOccurrences(storage, siteKey) < 1) ||
      storage === null  // fixes issue where local storage wasn't getting set, and close button wasn't working
    ) {
      const newEntry = {
        site: siteKey,
        status: desiredStatus
      };

      if (storage === null) {
        storage = [];
      }
      storage.push(newEntry);

      window.localStorage.setItem("bannerStatus", JSON.stringify(storage));
      return true;
    }
    return false;
  };

  const closeBanner = siteKey => {
    // eslint-disable-next-line array-callback-return
    storage.map(state => {
      if (state.site === siteKey) {
        state.status = "closed";
      }
    });
    const updated = JSON.stringify(storage);
    window.localStorage.setItem("bannerStatus", updated);
  };

  let currentState = getBannerStatus(urlPath);

  if (currentState === undefined || currentState === null || currentState === "") {
    setBannerStatus(urlPath, "open");
    banner.style.display = "block";
    currentState = getBannerStatus(urlPath);
  }

  if (currentState === "open") {
    if (bannerButton !== null) {
      banner.style.display = "block";
      bannerButton.addEventListener("click", () => {
        closeBanner(urlPath);
        banner.style.display = "none";
      });
    }
  }
}
